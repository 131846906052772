<template>
  <v-container fluid>
    <section>
      {{ routeCoordinates }}
      <l-map
        class="VueLeafMap"
        :zoom="zoom"
        :center="MapCenter"
        :bounds="bounds"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <l-control-fullscreen
          position="topleft"
          :options="{ title: { false: 'Full Screen', true: 'Dashboard View' } }"
        />
        <!-- Markers  -->
        <!--Route Start and End Marker -->
        <template v-for="(points, i) in routeMarker">
          <l-marker
            :key="`marker-${i}`"
            :lat-lng="points"
          >
            <l-icon v-if="i == 0">
              <v-icon
                color="#000"
                x-large
              >
                mdi-google-maps
              </v-icon>
            </l-icon>

            <l-icon v-else-if="i == routeMarker.length - 1">
              <Icons name="marker-end" />
            </l-icon>
          </l-marker>
        </template>

        <!-- Vehicle Pnt Markers -->
        <template v-for="(coor, j) in vehicleMarkers">
          <template v-for="(points, i) in coor">
            <l-marker
              :key="`vehicle-${j}-${i}`"
              :lat-lng="points"
            >
              <l-icon v-if="i == 0">
                <v-icon
                  color="#000"
                  x-large
                >
                  mdi-google-maps
                </v-icon>
              </l-icon>

              <l-icon v-else-if="i == routeMarker.length - 1">
                <Icons name="marker-end" />
              </l-icon>
            </l-marker>
          </template>
        </template>

        <!-- Markers  -->
        <!-- Polyline -->
        <l-polyline
          :lat-lngs="routeCoordinates"
          :color="'green'"
        />
        <template v-for="(veh, i) in vehiclePoint">
          <l-polyline
            :key="i"
            :lat-lngs="veh"
            :color="randomColor()"
          />
        </template>

        <!-- Polyline -->
      </l-map>
    </section>
  </v-container>
</template>
<script>
import { latLngBounds } from "leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import {
  LMap,
  LTileLayer,
  //   LControl,
  LMarker,
  LPolyline,
  LIcon,
  //   LPopup,
} from "vue2-leaflet";
import { mapGetters } from "vuex";
export default {
  components: {
    LMap,
    LTileLayer,
    // LControl,
    LMarker,
    LIcon,
    LPolyline,
    Icons: () => import("@/components/base/icons.vue"),
    LControlFullscreen,
    // LPopup,
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      //
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      routePlan: "routeListing/getCompareRoute",
    }),

    bounds() {
      let arr = [];
      if (this.routeCoordinates.length > 0) {
        // const routeLength = this.routeCoordinates.length - 1;
        // arr.push(this.routeCoordinates[0]);
        arr.push(this.routeCoordinates);
      }
      //   defual location when nothing is selected
      else {
        arr.push([24.8785, 67.0641]);
      }
      return latLngBounds(arr);
    },
    routeMarker() {
      let arr = [];
      if (this.routeCoordinates.length > 0) {
        const len = this.routeCoordinates.length;
        arr.push(this.routeCoordinates[0]);
        arr.push(this.routeCoordinates[len - 1]);
      }
      return arr;
    },
    vehicleMarkers() {
      const arr = [];
      if (this.vehiclePoint.length > 0) {
        let sPnt = [];
        let ePnt = [];
        this.vehiclePoint.forEach((el) => {
          const len = el.length;
          sPnt.push(el[0]);
          ePnt.push(el[len - 1]);
        });
        arr.push(sPnt);
        arr.push(ePnt);
      }

      return arr;
    },
    routeCoordinates() {
      let arr = [];
      if (this.routePlan?.routes) {
        let pnt = this.routePlan.routes?.cordinates;
        pnt.forEach((el) => {
          el.coordinates.forEach((coor) => {
            arr.push([coor[1], coor[0]]);
          });
        });
      }

      return arr;
    },
    vehiclePoint() {
      let arr = [];
      if (this.routePlan?.vehicles) {
        this.routePlan?.vehicles.forEach((veh) => {
          arr.push(veh.position.map((r) => [r.latitude, r.longitude]));
        });
      }
      return arr;
    },
    MapCenter() {
      return [24.8785, 67.0641];
    },
  },
  beforeDestroy() {
    // this.$store.commit("routeListing/SET_ROUTE_PLAN", []);
  },
  async mounted() {},
  methods: {
    fetchData() {},
    randomColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
  },
};
</script>
<style lang="sass" scoped>
.VueLeafMap
    z-index: 1 !important
    height: 400px
</style>
